.loadingDots {
    background-color: transparentize(lighten($primary-bg, 10%), 0.5);
    z-index: 3;
    margin-top: 80px;
    padding-left: 20px;
}

.navbar {
    font-weight: 500;
    background-color: $primary-bg;
    .navbar-toggler {
        border-color: $primary;
    }
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='" + $primary-string + "' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
}

.darkModeSwitch {
    label, span {
        margin-bottom: 0px;
        vertical-align: middle;
    }
    > label {
        margin-left: 5px;
        margin-bottom: 1px;
    }
}

// Switch
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
}

/* Hide default HTML checkbox */

.switch input {
    display: none;
}

/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $secondary;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: $primary;
}

input:focus+.slider {
    box-shadow: 0 0 1px $primary;
}

input:checked+.slider:before {
    transform: translateX(18px);
}

/* Rounded sliders */

.slider.round {
    border-radius: 22px;
}

.slider.round:before {
    border-radius: 50%;
}