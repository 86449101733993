$activeTile: $primary;
$flippedTile: $secondary;
.gamePage {
    .board {
        .boardRow {
            display: flex;
            text-align: center;
            justify-content: center;
        }
    }
    .exampleBox {
        p {
            margin-bottom: 0px;
            margin-top: 5px;
        }
        .boldTip {
            font-weight: 700;
        }
        .example {
            display: flex;
            align-items: center;
        }
    }
    .panel {
        word-wrap: break-word;
        .moves {
            max-height: 200px;
            overflow-y: auto;
        }
    }
    .highScores {
        overflow-x: auto;
        table {
            width: 100%;
            thead>tr {
                border-bottom: 2px $primary solid;
            }
            tr {
                border-top: 1px $secondary solid;
            }
            td {
                padding: 3px;
                padding-right: 8px;
            }
        }
    }
    .tile {
        font-size: 2vh;
        max-width: 50px;
        width: 100%; //margin: 0.2%;
        margin: 1px;
        font-size: 14px;
        @media (min-width: 576px) {
            //margin: 0.15%;
        }
        perspective: 1000px;
        user-select: none;
        .tileInside {
            padding-top: 100%;
            width: 100%;
            transition: 0.25s;
            transform-style: preserve-3d;
            position: relative;
            border-radius: 5%;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        }
        .front,
        .back {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            backface-visibility: hidden;
            >span {
                pointer-events: none;
            }
        }
        .front {
            //z-index: 2;
            transform: rotateY(0deg); //background-color: $activeTile;
        }
        .back {
            transform: rotateY(180deg); //background-color: $flippedTile;
        }
        &.active {
            .tileInside {
                background-color: $activeTile;
                color: $activeTile;
                cursor: pointer;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                @media (hover: hover) {
                    &:hover {
                        background-color: lighten($activeTile, 20%);
                        color: $flippedTile;
                    }
                }
            }
        }
        &.flipped {
            .tileInside {
                transform: rotateY(180deg);
                background-color: $flippedTile;
                color: $flippedTile;
                cursor: pointer;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                @media (hover: hover) {
                    &:hover {
                        background-color: lighten($flippedTile, 20%);
                        color: $activeTile;
                    }
                }
            }
        }
        &.inactive {
            visibility: hidden;
        }
        &.disabled {
            .tileInside {
                pointer-events: none;
                transition: none !important;
            }
        }
        &.coords {
            .front {
                background-color: none;
            }
            .back {
                visibility: hidden;
            }
        }
    }
}