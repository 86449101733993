@import 'fonts';
@import 'vendor';
@import '../components/common/header';
@import '../components/game/game';
@import '../components/contact/contact';

.main-container {
    padding-top: 80px;
    height: 100vh;
}

.jumbotron {
    background-color: $secondary;
}

a {
    color: $links;
    &.active {
        color: $active;
    }
    &:hover {
        text-decoration: none;
    }
}

section {
    margin-bottom: 100px;
}

.simpleBox {
    margin-top: 5px;
    margin-bottom: 5px;
}

hr {
    border-color: $primary;
    border-width: 2px;
    max-width: 100%;
}

ul {
    margin: 0;
    padding-left: 20px;
    margin-bottom: 10px;
    &.dashed {
        list-style-type: none;
        >li {
            margin-top: 12px;
            &:before {
                content: "- ";
            }
        }
    }
}

.btn {
    margin: 3px;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    &:focus {
        outline: none;
        box-shadow: none;
    }
    @media (hover: none) {
        &:hover {
            background-color: $primary;
        }
    }
}

::selection {
    color: white;
    background: $primary;
}

#toast-container {
    .toast {
        box-shadow: none;
        opacity: 0.9;
    }
}